<template>
  <li
    :class="[
      'text-sgray-900 hover:text-gray-200',
      isIconNavigation ? 'py-2' : 'mb-1',
    ]"
    class="rounded-sm last:mb-0"
  >
    <slot :handle-click="handleClick" :expanded="expanded" />
  </li>
</template>

<script setup lang="ts">
  import { ref } from 'vue'

  const props = defineProps<{
    activeCondition: boolean
    isIconNavigation: boolean
  }>()

  const expanded = ref(props.activeCondition)

  export interface SSidebarLinkGroup {
    handleClick: () => void
  }

  const handleClick = () => {
    expanded.value = !expanded.value
  }
</script>
