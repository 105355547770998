<template>
  <component :is="icon" />
</template>

<script setup lang="ts">
  import * as outline from '@heroicons/vue/outline'
  import * as solid from '@heroicons/vue/solid'
  import { computed } from 'vue'

  const props = withDefaults(
    defineProps<{
      name: string
      solid?: boolean
    }>(),
    { solid: true }
  )

  const icons = computed((): any => {
    return props.solid ? solid : outline
  })

  const icon = computed((): string => {
    return icons.value[props.name]
  })
</script>
