<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol class="inline-flex items-center space-x-1 md:space-x-3">
      <li class="inline-flex items-center">
        <router-link to="/home">
          <HomeIcon class="w-4 h-4" />
        </router-link>
      </li>
      <template v-for="(breadcrumb, index) in items" :key="index">
        <li>
          <div class="flex items-center">
            <ChevronRightIcon class="w-5 h-5" />
            <template v-if="breadcrumb.path">
              <router-link
                class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2"
                :to="breadcrumb.path"
              >
                {{ breadcrumb.name }}
              </router-link>
            </template>
            <template v-else>
              <span class="ml-1 text-sm font-medium text-gray-700 md:ml-2">
                {{ breadcrumb.name }}
              </span>
            </template>
          </div>
        </li>
      </template>
    </ol>
  </nav>
</template>

<script setup lang="ts">
  import { HomeIcon, ChevronRightIcon } from '@heroicons/vue/solid'
  import { useBreadcrumbs } from '@/store/useBreadcrumbs'
  import { storeToRefs } from 'pinia'

  const breadcrumbsStore = useBreadcrumbs()
  const { items } = storeToRefs(breadcrumbsStore)
</script>
