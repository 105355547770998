<template>
  <div class="flex h-screen overflow-hidden">
    <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />

    <div
      class="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-gray-200"
    >
      <Header
        :sidebar-open="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />

      <main id="main" class="flex flex-grow">
        <div class="flex flex-grow p-4 lg:p-6 w-full max-w-9xl">
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import Sidebar from './layout/SSidebar.vue'
  import Header from './layout/SHeader.vue'

  const sidebarOpen = ref(false)
</script>
