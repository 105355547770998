<template>
  <header class="sticky top-0 bg-white border-b border-gray-200 z-30">
    <div>
      <div class="absolute top-0 left-0 right-0 flex flex-wrap">
        <SProgressBar
          v-if="loading.isLoading"
          :percentage="100"
          indeterminate
        />
      </div>
    </div>
    <div class="px-6">
      <div class="flex items-center h-12 -mb-px">
        <div class="flex">
          <button
            class="text-gray-500 hover:text-gray-600 lg:hidden"
            aria-controls="sidebar"
            :aria-expanded="sidebarOpen"
            @click.stop="$emit('toggle-sidebar')"
          >
            <span class="sr-only">Open sidebar</span>
            <MenuIcon class="w-6 h-6 fill-current" />
          </button>
        </div>
        <div class="flex ml-4 lg:ml-0">
          <SBreadCrumbs />
        </div>
        <div class="flex items-center ml-auto">
          <UserMenu />
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
  import { MenuIcon } from '@heroicons/vue/outline'
  import UserMenu from '@/view/partials/layout/SUserMenu.vue'
  import SBreadCrumbs from '@/view/partials/layout/SBreadCrumbs.vue'
  import SProgressBar from '@/components/SProgressBar/SProgressBar.vue'
  import { useLoading } from '@/store/useLoading'

  const loading = useLoading()

  defineProps<{ sidebarOpen: boolean }>()
  defineEmits<{
    (event: 'toggle-sidebar'): void
  }>()
</script>
