import { Auth } from '@aws-amplify/auth'

const AmplifyMiddleware = {
  init() {
    Auth.configure({
      region: import.meta.env.VITE_COGNITO_REGION,
      userPoolId: import.meta.env.VITE_COGNITO_USERPOOL_ID,
      userPoolWebClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
      mandatorySignIn: true,
      authenticationFlowType: 'REFRESH_TOKEN_AUTH',
      oauth: {
        domain: import.meta.env.VITE_COGNITO_APP_DOMAIN,
        scope: [],
        redirectSignIn: import.meta.env.VITE_COGNITO_REDIRECT_URI,
        redirectSignOut: import.meta.env.VITE_COGNITO_REDIRECT_URI_SIGNOUT,
        responseType: 'code',
      },
    })
  },
}

export default AmplifyMiddleware
