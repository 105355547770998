import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

export const useSidebar = defineStore(
  'sidebar',
  () => {
    const sidebarExpanded = ref(true)

    watch(
      sidebarExpanded,
      (value) => {
        if (value) {
          document.body.classList.add('sidebar-expanded')
        } else {
          document.body.classList.remove('sidebar-expanded')
        }
      },
      { immediate: true }
    )

    return {
      sidebarExpanded,
    }
  },
  {
    persist: true,
  }
)
