import { defineStore } from 'pinia'
import { ref } from 'vue'

export interface Breadcrumbs {
  name: string
  path?: string
}

export const useBreadcrumbs = defineStore('breadcrumbs', () => {
  const items = ref<Breadcrumbs[]>([])

  function setBreadcrumbs(breadcrumbs: Breadcrumbs[]): void {
    items.value = breadcrumbs
  }
  return {
    items,
    setBreadcrumbs,
  }
})
