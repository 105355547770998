export function jsonPathToValue(path: string, object: Record<string, any>) {
  return path.split('.').reduce((o, k) => o && o[k], object)
}

export function findPropertyPath(
  obj: Record<string, any>,
  objKey: string
): string {
  const path: string[] = []
  let pathFound = false
  findPath(obj, objKey)
  function findPath(obj: Record<string, unknown>, objKey: string) {
    for (const key in obj) {
      if (key === objKey) {
        pathFound = true
        return path.push(key)
      }
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        path.push(key)
        findPath(obj[key] as Record<string, unknown>, objKey)
      }
    }
    if (!pathFound) {
      path.pop()
    }
  }

  return path.join('.')
}
