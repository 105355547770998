import { RouteRecordRaw } from 'vue-router'
import { casesRoutes } from '@/view/pages/reports/cases/_routes'
import { changesRoutes } from '@/view/pages/reports/changes/_routes'
import { incidentsRoutes } from '@/view/pages/reports/incidents/incident/_routes'
import { serviceRequestsRoutes } from '@/view/pages/reports/incidents/service_request/_routes'
import { securityIncidentsRoutes } from '@/view/pages/reports/incidents/security_incident/_routes'
import { problemsRoutes } from '@/view/pages/reports/problems/_routes'

const reportsRoutes: RouteRecordRaw = {
  name: 'Reports',
  path: 'reports',
  redirect: { name: 'Open Cases' },
  component: () => import('@/view/partials/AppLayout.vue'),
  children: [
    casesRoutes,
    changesRoutes,
    incidentsRoutes,
    serviceRequestsRoutes,
    securityIncidentsRoutes,
    problemsRoutes,
  ],
}

export { reportsRoutes }
