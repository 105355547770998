import { COUNTRY_ENUM } from '@/constants/countries'
import { Nullable } from '@/types/base'

export function abbreviate(emailInput: string) {
  if (emailInput) {
    const segments = emailInput.split('@')
    if (segments.length == 2) {
      const nameSegments = segments[0].split('.')
      if (nameSegments.length > 1) {
        return (
          nameSegments[0]
            .split(' ')
            .map((n) => n[0])
            .join('')
            .toUpperCase() +
          nameSegments[1]
            .split(' ')
            .map((n) => n[0])
            .join('')
            .toUpperCase()
        )
      }
    }
  } else {
    return ''
  }
}

export function convertRangeToSinceUntilString(range: string[]) {
  if (range.length == 2) {
    return `SINCE '${range[0]}' UNTIL '${range[1]}'`
  } else {
    return null
  }
}

export function convertToVATString(
  vat: Nullable<string>,
  country: Nullable<string>
) {
  if (vat && country) {
    const countryEnum = COUNTRY_ENUM[country.toUpperCase()]
    vat = `${countryEnum?.CODE ?? ''} ${vat}`
  }
  return vat
}
