import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useDialog = defineStore('Dialog', () => {
  const value = ref<boolean>(false)
  const titleRef = ref<string>('')
  const textRef = ref<string>('')
  const isConfirm = ref<boolean>(false)

  let resolveFn: (value: boolean) => void = () => undefined

  function showDialog(text: string, title?: string): void {
    value.value = true
    titleRef.value = title ?? 'Alert'
    textRef.value = text
    isConfirm.value = false
  }

  function showConfirm(text: string, title?: string): Promise<boolean> {
    value.value = true
    titleRef.value = title ?? 'Confirmation'
    textRef.value = text
    isConfirm.value = true
    return new Promise((resolve) => {
      resolveFn = resolve
    })
  }

  function clickConfirmOption(): void {
    resolveFn(true)
  }

  function clickCancelOption(): void {
    resolveFn(false)
  }

  function closeDialog(): void {
    value.value = false
  }

  return {
    value,
    isConfirm,
    title: titleRef,
    text: textRef,
    showConfirm,
    showDialog,
    clickConfirmOption,
    clickCancelOption,
    closeDialog,
  }
})
