<template>
  <transition-group
    :class="classList"
    tag="ul"
    class="fixed z-40 overflow-hidden"
    enter-active-class="transform-gpu"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transform-gpu"
    leave-from-class="opacity-100 translate-x-0"
    leave-to-class="opacity-0 translate-x-full"
  >
    <SNotificationListItem
      v-for="notification in notificationList"
      :key="notification.id"
      :notification="notification"
      class="transition transform ease-in-out duration-500"
    />
  </transition-group>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { useNotificationStore } from '@/store/useNotificationStore'
  import SNotificationListItem from '@/components/SNotification/SNotificationListItem.vue'
  import { Notification } from '@/components/SNotification/types'

  const notificationStore = useNotificationStore()
  const notificationList = computed<Record<string, Notification>>(() => {
    return Object.keys(notificationStore.notifications)
      .reverse()
      .reduce(
        (previous, current) => ({
          ...previous,
          [current]: notificationStore.notifications[current],
        }),
        {}
      )
  })

  const classList = computed(() => `absolute top-0 right-0 mt-2 mr-6 z-50`)
</script>
