import { graphql } from '@/gql/gql'

const LIST_CONFIGURATION_ITEMS = graphql(`
  query ListCI(
    $filters: ServiceNowConfigurationItemFilter
    $pagination: GenericPaginationLimitOffset
  ) {
    operations {
      configurationItems(filters: $filters, pagination: $pagination) {
        result {
          cpuCount
          hostName
          installStatus
          ipAddress
          name
          osInformation {
            name
            version
          }
          ram
          shortDescription
          sysClassName
          sysId
          logicalTag
          patchDaySchedule
          patchMonthSchedule
          patchTime
          patchWeekSchedule
          patchingDisabledReason
          patchingEnabled
          supportLevel
          vmTechnology
        }
        totalCount
        metadata {
          conversationId
          message
          statusCode
          statusText
        }
      }
    }
  }
`)

const LIST_CI_CSV_EXPORT = graphql(`
  query ListCICsvExport(
    $filters: ServiceNowConfigurationItemFilter
    $pagination: GenericPaginationLimitOffset
  ) {
    operations {
      configurationItems(filters: $filters, pagination: $pagination) {
        result {
          hostName
          installStatus
          name
          osInformation {
            name
            version
          }
          shortDescription
          sysClassName
          sysId
          supportLevel
          vmTechnology
        }
        totalCount
        metadata {
          conversationId
          message
          statusCode
          statusText
        }
      }
    }
  }
`)

export { LIST_CONFIGURATION_ITEMS, LIST_CI_CSV_EXPORT }
