import '@/assets/scss/style.scss'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import FloatingVue from 'floating-vue'
import PersistedState from 'pinia-plugin-persistedstate'
import VueApexCharts from 'vue3-apexcharts'
import router from './view/pages/router'
import { apolloClient } from '@/middleware/apollo'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { provideApolloClient } from '@vue/apollo-composable'

import { CaptureConsole } from '@sentry/integrations'

// Middleware
import AmplifyMiddleware from '@/middleware/amplify'
AmplifyMiddleware.init()

declare global {
  interface Navigator {
    msSaveBlob?: (blob: unknown, defaultName?: string) => boolean
  }
}

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://8dd9626852b8424aa08349bbba21c6a2@o206765.ingest.sentry.io/4504514660532224',
  enabled: import.meta.env.VITE_ENV !== 'dev',
  environment: import.meta.env.VITE_ENV,
  integrations: [new CaptureConsole({ levels: ['error'] })],
  tracesSampleRate: 1.0,
  tunnel: import.meta.env.VITE_SENTRY_TUNNEL,
})

app
  .use(router)
  .use(createPinia().use(PersistedState))
  .use(VueApexCharts)
  .use(FloatingVue, { themes: { tooltip: { placement: 'bottom' } } })
  .mount('#app')

provideApolloClient(apolloClient)
