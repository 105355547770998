<template>
  <transition
    enter-active-class="transition ease-out duration-200 transform"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-200 transform"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-show="value"
      class="fixed z-[90] inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >

        <div
          ref="refDialog"
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-2 sm:text-left">
                <h3
                  id="modal-title"
                  class="text-lg leading-6 font-medium text-gray-900"
                >
                  {{ title }}
                </h3>
                <div class="mt-2 py-8">
                  <!-- eslint-disable vue/no-v-html -->
                  <div class="text-sm text-gray-500" v-html="text" />
                  <!-- eslint-enable -->
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 flex justify-end border-t border-gray-200 px-6 py-4">
            <SButton size="sm" color="dark" text @click="events.onClickClose">{{
              isConfirm ? 'CANCEL' : 'CLOSE'
            }}</SButton>
            <template v-if="isConfirm">
              <SButton
                size="sm"
                color="primary"
                text
                @click="events.onClickConfirm"
                >YES</SButton
              >
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import { onClickOutside, onKeyStroke } from '@vueuse/core'
  import { useDialog } from '@/store/useDialog'
  import { storeToRefs } from 'pinia'
  import SButton from '@/components/SButton/SButton.vue'

  const dialogStore = useDialog()
  const { value, text, title, isConfirm } = storeToRefs(dialogStore)

  const refDialog = ref(null)
  onClickOutside(refDialog, () => events.onClickClose())
  onKeyStroke('Escape', () => {
    events.onClickClose()
  })

  const events = {
    onClickClose() {
      dialogStore.clickCancelOption()
      dialogStore.closeDialog()
    },
    onClickConfirm() {
      dialogStore.clickConfirmOption()
      dialogStore.closeDialog()
    },
  }
</script>
