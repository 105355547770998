import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import {
  useGlobalMutationLoading,
  useGlobalQueryLoading,
} from '@vue/apollo-composable'

export const useLoading = defineStore('loading', () => {
  const loadingCount = ref(0)
  const isLoading = computed(() => loadingCount.value > 0)

  watch(useGlobalQueryLoading(), (value) => {
    if (!value) loadingCount.value = 0
  })
  watch(useGlobalMutationLoading(), (value) => {
    if (!value) loadingCount.value = 0
  })

  watch(loadingCount, (value) => {
    if (value < 0) loadingCount.value = 0
  })

  return {
    isLoading,
    loadingCount,
  }
})
