<template>
  <template
    v-if="
      props.navigationItem.subMenu &&
      authentication.isAllowed(props.navigationItem.roles)
    "
  >
    <SidebarLinkGroup
      v-slot="parentLink"
      :active-condition="isChildActive(props.navigationItem)"
      :is-icon-navigation="!!props.navigationItem.icon"
    >
      <a
        class="block truncate transition duration-150"
        href="#0"
        @click.prevent="
          sidebarExpanded ? parentLink.handleClick() : (sidebarExpanded = true)
        "
      >
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <SIcon
              v-if="props.navigationItem.icon"
              :name="props.navigationItem.icon.name"
              :solid="props.navigationItem.icon.solid"
              class="shrink-0 h-6 w-6 duration-200"
            />
            <span v-if="!props.navigationItem.icon" class="align-middle"
              >&#8226;</span
            >
            <span
              class="text-sm font-medium ml-2.5 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
              >{{ props.navigationItem.name }}
            </span>
          </div>
          <!-- Icon -->
          <div class="flex shrink-0 ml-2">
            <svg
              class="w-3 h-3 shrink-0 ml-1 fill-current text-gray-400"
              :class="parentLink.expanded && 'transform rotate-180'"
              viewBox="0 0 12 12"
            >
              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
            </svg>
          </div>
        </div>
      </a>
      <div class="lg:hidden lg:sidebar-expanded:block">
        <ul class="pl-5 mt-1" :class="!parentLink.expanded && 'hidden'">
          <template
            v-for="(subMenu, subIndex) in props.navigationItem.children"
            :key="subIndex"
          >
            <template v-if="subMenu.subMenu">
              <SSidebarMultiLevel
                :navigation-item="subMenu"
              ></SSidebarMultiLevel>
            </template>
            <template v-else>
              <router-link
                v-if="authentication.isAllowed(subMenu.roles)"
                v-slot="{ href, navigate, isActive }"
                :to="subMenu.path"
                custom
              >
                <li class="mb-1 last:mb-0">
                  <a
                    class="block transition duration-150 truncate"
                    :class="[
                      isActive
                        ? 'text-sgold-500 hover:text-sgold-500'
                        : 'text-sgray-900 hover:text-gray-200',
                    ]"
                    :href="href"
                    @click="navigate"
                  >
                    <span class="align-middle mr-2.5">&#8226;</span>
                    <span
                      class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 justify-center"
                    >
                      {{ subMenu.name }}
                    </span>
                  </a>
                </li>
              </router-link>
            </template>
          </template>
        </ul>
      </div>
    </SidebarLinkGroup>
  </template>
  <template v-else>
    <router-link
      v-if="authentication.isAllowed(props.navigationItem.roles)"
      v-slot="{ href, navigate, isActive }"
      :to="props.navigationItem.path"
      custom
    >
      <li
        class="py-2 rounded-sm mb-0.5 last:mb-0"
        :class="isActive && 'bg-sblack-600'"
      >
        <a
          class="block truncate transition duration-150"
          :class="[
            isActive ? 'text-sgold-500' : 'text-sgray-900',
            isActive ? 'hover:text-sgold-500' : 'hover:text-gray-200',
          ]"
          :href="href"
          @click="navigate"
        >
          <div class="flex items-center">
            <SIcon
              :name="props.navigationItem.icon.name"
              :solid="props.navigationItem.icon.solid"
              class="shrink-0 h-6 w-6 duration-200"
            />
            <span
              class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 duration-200"
            >
              {{ props.navigationItem.name }}
            </span>
          </div>
        </a>
      </li>
    </router-link>
  </template>
</template>
<script setup lang="ts">
  import { useRouter } from 'vue-router'
  import { ref } from 'vue'
  import SidebarLinkGroup from './SSidebarLinkGroup.vue'
  import { useAuthentication } from '@/store/useAuthentication'
  import SIcon from '@/components/SIcon/SIcon.vue'
  import { useSidebar } from '@/store/useSidebar'
  import { storeToRefs } from 'pinia'
  import { NavigationItem, NavigationItemChildren } from '@/types/base'

  const props = defineProps<{
    navigationItem: NavigationItem | NavigationItemChildren
  }>()

  const router = useRouter()
  const currentRoute = ref(router.currentRoute.value)
  const authentication = useAuthentication()

  const sidebarStore = useSidebar()
  const { sidebarExpanded } = storeToRefs(sidebarStore)

  const isChildActive = (menu: NavigationItem | NavigationItemChildren) => {
    if ('children' in menu && menu.children) {
      return menu.children.some((element) => {
        return currentRoute.value.fullPath.includes(element.groupPath ?? 'N/A')
      })
    }
  }
</script>
